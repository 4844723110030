/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use './variables.scss' as var;

@include mat.elevation-classes();
@include mat.app-background();
// current theme

.primary-bg {
    background-color: var.$old-primary-color !important;
}

.accent-bg {
    background-color: var.$old-accent-color !important;
}

.warn-bg {
    background-color: map-get(var.$warn, 700) !important;
}

.failure {
    background-color: map-get(var.$warn, 700) !important;
}

.success {
    background-color: var.$old-success !important;
}

.failure-text {
    color: map-get(var.$warn, 700) !important;
}

.success-text {
    color: var.$old-success !important;
}

.accent-text {
    color: var.$old-accent-color !important;
}

.selected {
    background-color: var.$selected-color !important;
}

.primary-text {
    color: var.$primary-color !important;
}

.selected-icon {
    color: map-get(var.$primary, 200) !important;
}

.mat-button-base.ei-export {
    padding: 0;
    background-color: #f5f5f5 !important;
    min-width: 40px;
}

// Setting up the text color

@function createForeground($color) {
    @return (
        base: black,
        //toggle button groups borders, text underlines, table borders
        divider: var.$divider-color,
        dividers: var.$divider-color,
        //disabled:          rgba($color, 0.38),
        //disabled-button:   rgba($color, 0.38),
        //disabled-text:     rgba($color, 0.38),
        //elevation: black,
        hint-text: rgba($color, 0.38),
        // Placeholders
        secondary-text: var.$light-text-color,
        icon: var.$icon-color,
        icons: var.$icon-color,
        // button text, paginator, input text  tags: p, span
        text: $color,
        //slider-min:        rgba($color, 0.38)
        //slider-off:        rgba($color, 0.26),
        //slider-off-active: rgba($color, 0.38),
    );
}

@function createBackground($color) {
    @return (
        //status-bar: #ff0000,
        //app-bar: #f5f5f5,
        //background: var.$body-background,
        background: var.$body-background,
        card: #fff,
        //hover: rgba(0, 0, 0, 0.04),
        //card: white,
        //dialog: white,
        //disabled-button:
        //rgba(0, 0, 0, 0.12),
        //raised-button: white,
        //focused-button: rgba(0, 0, 0, 0.12),
        selected-button: var.$selected-button,
        //selected-disabled-button: #bdbdbd,
        //disabled-button-toggle: #eeeeee,
        unselected-chip: var.$unselected-chip,
        //disabled-list-option: #eeeeee
    );
}

$my-foreground: createForeground(#707070);
$my-background: createBackground(#707070);

$primary: mat.m2-define-palette(var.$primary-palette);
$accent: mat.m2-define-palette(var.$accent-palette, 500, 900, A100);
$warn: mat.m2-define-palette(var.$warn-palette);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
    )
);

@include mat.all-component-themes($my-theme);
@include mat.form-field-density(-3);

mat-icon {
    color: map-get($my-foreground, icon);
}

h1 {
    color: map-get($my-foreground, base);
}

.title {
    color: var.$dark-text-color;
}

.sub-title {
    color: map-get($my-foreground, secondary-text);
}

.text-color {
    color: var.$text-color;
}

.failure-text {
    color: map-get(var.$warn-palette, 500) !important;
}

html,
body {
    background: map-get($my-background, background);
}
