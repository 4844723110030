@use 'frontend/src/scss/variables.scss' as var;
.new-design {
    & header.page-title {
        text-align: center;
        color: var.$dark-text-color;
        font-size: 45px;
        padding: 20px;
    }

    & a {
        color: var.$link-color;
    }

    & mat-card {
        padding: 0;

        & > * {
            padding: 10px;
        }

        & mat-card-content {
            border-top: 2px solid var.$divider-color;

            &:first-child {
                border-top: none;
            }

            &.no-border {
                border-top: none;
                padding-top: 0;
            }
        }
    }

    & img.menu-icon {
        width: 20px !important;
        margin: 0;
    }

    & mat-icon.menu-icon {
        width: 25px !important;
        margin: 0;
    }

    & mat-icon.input-search-icon {
        top: -5px;
        right: -5px;
        font-size: 20px !important;
    }

    & mat-chip-option {
        font-size: 12px;

        &.selected {
            & * {
                color: var.$primary-color;
            }
        }
    }

    & * {
        & .input-label {
            display: block;
            color: var.$light-text-color;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
        }

        & .filter-bar:not(.ignore-last) {
            & *:last-child {
                margin-left: auto;
            }
        }

        & .dialog-success {
            border: 2px solid var.$success-color;
            background-color: rgba(158, 249, 85, 0.25);
            text-align: center;
            color: var.$dark-text-color;

            & button {
                color: var.$dark-text-color;
            }
        }

        & .dialog-failure {
            border: 2px solid var.$warn-color;
            background-color: rgba(243, 97, 90, 0.25);
            text-align: center;
            color: var.$dark-text-color;

            & button {
                color: var.$dark-text-color;
            }
        }

        & button {
            &.mat-stroked-button {
                min-width: 45px;
            }

            &.action-icon {
                background-color: var.$body-background;
                min-width: 45px;
            }
        }

        & table.mat-table {
            & tr.selected-row {
                background-color: var.$selected-color;

                &:hover {
                    box-shadow: none !important;
                }
            }
        }

        & .multiline-ellipsis {
            overflow: hidden;
            position: relative;
            line-height: 1.2em;
            max-height: 3.6em;
            text-align: justify;
            margin-right: -1em;
            padding-right: 1em;

            &:before {
                content: '...';
                position: absolute;
                right: 0;
                bottom: 0;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: 0.2em;
                background: white;
            }
        }
    }

    & mat-form-field {
        &.filter-select {
            &.active:not(.mat-form-field-invalid) * {
                color: var.$primary-color;

                &::placeholder {
                    color: var.$primary-color;
                }
            }

            & .mat-mdc-form-field-subscript-wrapper {
                margin-top: 0.066667em;
            }

            & .mat-mdc-select-arrow {
                border: 0;
            }

            .icon {
                font-size: 135%;
                transform: translateY(3px);

                &.clock {
                    font-size: 85%;
                    transform: translateY(0px);
                }

                &.calendar {
                    font-size: 60%;
                    transform: translateY(-15px);
                }
            }
        }
    }

    div.eaglei-filter-select-location-menu {
        max-height: 200px;
        min-width: 200px;

        & mat-checkbox {
            display: block;
        }
    }

    & .menu-icon {
        width: 20px;
        margin: 0;
    }

    & .svg-icon {
        filter: invert(94%) sepia(0%) saturate(0%) hue-rotate(167deg) brightness(90%) contrast(82%);
    }

    & .current-status {
        & * {
            color: #0288f3;
        }

        & img {
            // Got values from https://codepen.io/sosuke/pen/Pjoqqp
            filter: invert(38%) sepia(46%) saturate(3689%) hue-rotate(188deg) brightness(98%) contrast(99%);
        }
    }

    & .mat-mdc-menu-item {
        height: 40px;
        font-size: 14px;
        color: var.$text-color;
        background: transparent;

        & .mat-icon {
            margin-right: 2px;
            padding-top: 3px;
        }

        & .mat-icon-no-color {
            color: var.$icon-color;
        }
    }
}

//mat-form-field {
//  & input {
//    color: var.$text-color;
//  }
//}

eaglei-date-range {
    .mat-mdc-form-field-infix {
        display: flex;
    }

    .mat-mdc-form-field.multiple-inputs {
        .mat-mdc-form-field-icon-suffix {
            padding: 0;
        }

        .mat-mdc-select-value {
            margin: 0;
        }
    }
}
