/* This is a patch to fix times when clicking in the center of the checkbox didnt trigger the (change) event. 
Ticket: https://jira.ornl.gov/browse/EI-4651
Bug Report: https://github.com/angular/components/issues/30494
- J.Dillon
*/

mat-checkbox {
    input[type='checkbox'] {
        z-index: 400;
    }
}
