@use 'frontend/src/scss/variables.scss' as var;

.leaflet-control-container {
    height: 100%;
}

.leaflet-control-scale {
    position: absolute;
    bottom: 0;
    left: 20px;
}

.leaflet-bottom {
    z-index: 400;
}

.leaflet-control-scale-line {
    color: var.$primary-color;
    border-color: var.$primary-color;
}

.point-selection {
    cursor: crosshair !important;

    & * {
        cursor: crosshair !important;
    }
}

.leaflet-draw {
    display: none;
}

eaglei-leaflet-map {
    & .moveable {
        transition: all 1s ease-in-out;
    }

    & .slide-out {
        left: 400px !important; // sidebar width.
    }
}

.leaflet-layers-pane.location-search-active {
    pointer-events: none !important;

    & * {
        pointer-events: none !important;
    }
}

.leaflet-control-easyPrint {
    display: none;
}
