@use 'frontend/src/scss/variables.scss' as var;

.new-design {
    .dialog-header {
        border-bottom: 2px solid var.$divider-color;
        padding: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &:has(.mat-mdc-icon-button) {
            padding-right: 48px;
            position: relative;
        }

        .mat-mdc-icon-button {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .dialog-content {
        padding: 15px;

        .info-text {
            color: var.$text-color;
            font-size: 16px;
            line-height: 25px;
        }
    }

    .dialog-actions {
        padding: 15px;
        display: flex;
        flex-direction: row;
        justify-content: end;
    }
}

.dialog-header {
    font-size: 24px;
    color: var.$text-color;
    padding: 15px;

    fa-icon {
        cursor: pointer;
        width: 20px;
        height: 24px;
        font-size: 24px;
    }
}

.dialog-content {
    padding: 15px;
    overflow: auto;
}

.dialog-actions {
    padding: 15px;
}
